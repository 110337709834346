import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import appData from "./appData";
import settings from "./settings";
import library from "./library";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		settings,
		reports: appData,
		library,
	},
	state: {
		$p: null,
		instance: null,
		loading: false,
		folders: [],
		theApp: {},
		rightBar: "",
		selectedChart: {},
		currentStartDate: moment()
			.subtract(30, "days")
			.endOf("day")
			.format("x"),
		currentEndDate: moment()
			.endOf("day")
			.format("x"),
		compareStartDate: null,
		compareEndDate: null,
		records: {
			lead: {
				all: [],
				sources: [],
				opportunities: [],
			},
			inventory: {
				lots: [],
				elevations: [],
				priceHistory: {
					lots: [],
					elevation: [],
					units: [],
					premiums: [],
					addOns: [],
				},
			},
			pipeline: {
				all: [],
				tasks: [],
			},
			sales: [],
			survey: [],
		},
		fileStructure: [],
		selectedProjects: ['all'],
		pdfOrientation: "portrait",
		selectedSurvey: null,
		chartType: "bar",
	},
	mutations: {
		SET_SELECTED_PROJECTS(state, data = ['all']) {
			state.selectedProjects = data;
		},
		SET_PDF_ORIENTATION(state, data = "portrait") {
			state.pdfOrientation = data;
		},
		SET_SURVEY(state, data) {
			state.selectedSurvey = data;
		},
		SET_CHART_TYPE(state, data = "bar") {
			state.chartType = data;
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		SET_$P(state, value) {
			state.$p = value;
		},
		OPEN_RIGHT_BAR(state, data) {
			state.rightBar = data;
		},
		CLOSE_RIGHT_BAR(state) {
			state.rightBar = "";
		},
		LOAD: (state, bool = !state.loading) => state.loading = bool,
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons;
		},
		SET_APP(state, data) {
			state.theApp = data;
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data;
			state.cats = data.activeApps;
		},
		updateSelectedChart: (state, chartDetails) => {
			const chartData = chartDetails;
			delete chartData.link;
			state.selectedChart = chartData;
		},
		setCurrentDates(state, { startDate, endDate }) {
			state.currentStartDate = startDate;
			state.currentEndDate = endDate;
		},
		setCompareDates(state, { startDate, endDate }) {
			state.compareStartDate = startDate;
			state.compareEndDate = endDate;
		},
		setRecords(state, { type, records }) {
			state.records[type] = records;
		},
	},
	getters: {
		isPremiumPackage: state => {
			return state && state.instance && state.instance.package && ["PA-COM-LRP", "PA-CON-HRP"].includes(state.instance.package.itemCode)
		}
	},
	actions: {},
});
