<template>
    <div>
        <div
            :class="$style.subbar"
            v-if="instance"
            style="background-color: #f7f5f9"
        >
            <div class="dF aC mb-0">
                <div
                    :class="$style.breadcrumbs"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>{{
                            instance.name
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{
                            selectedAppInitial
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item style="color: var(--orange)">{{
                            crumbs
                        }}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        crumbs() {
            return this.$store.state.reports.crumbs;
        },
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "RA"
            );
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.ant-form-horizontal.search-horizontal {
    .ant-form-item-control-wrapper {
        flex: 1;
    }
    .ant-form-item {
        display: flex;
    }
    .ant-form-item-label {
        min-width: 100px;
    }
}
</style>
