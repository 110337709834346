<template>
  <div id="app">
    <BhLoading :show="loading" />
    <!-- <MediaSelector /> -->
    <LibrarySelector />
    <localization></localization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import LibrarySelector from 'bh-mod/components/file/LibrarySelector'
import BhLoading from 'bh-mod/components/common/Loading'

export default {
  name: 'app',
  components: { Localization,MediaSelector,LibrarySelector,BhLoading },
  computed: {
    ...mapGetters(['user']),
    loading(){
      return this.$store.state.loading
    },
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods:{

  }
}
</script>
<style lang="scss">
  #app{
    min-height: 100vh;
  }
  .display-card{
    border-radius: 3px;
    border-color:rgba(63,63,63, 0.15);
    box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15);
    border:none;
    background-color:#FFF;
  }
  .grid-display-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display:grid;
  }
  .hover-dotted{
    border-bottom:1px dotted transparent;
  }
  .hover-dotted:hover{
    display:inline-block;
    border-color:#ccc;
    cursor: pointer;
    color:var(--primary);
  }
  .table-card .ant-card-body{
    padding:0;
    .ant-table-pagination.ant-pagination{
      margin-right:2rem !important;
    }
  }


  body .ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab:last-child{
    margin-right:16px !important;
  }
  .ant-card.borderless{
    .ant-card-head{
      border-bottom:0;
    }
  }
</style>
